import React from 'react';
import { Link } from 'react-router-dom';
import "./Wrapper.css";

import itIcon from "./icons/it-icon.png";
import geoIcon from "./icons/geo-icon.png";
import ecoIcon from "./icons/economic-icon.png";
import anatIcom from "./icons/anatomy-icon.png";
import techIcon from "./icons/tech-icon.png";
import scienceIcon from "./icons/science-icon.png";
import historyIcon from "./icons/history-icon.png";
import mathIcon from "./icons/math-icon.png";
import chemIcon from "./icons/chem-icon.png";
import psychIcon from "./icons/psych-icon.png"
import physIcon from "./icons/phys-icon.png"
import langIcon from "./icons/lang-icon.png"



const categories = [
    { icon: itIcon, name: "Informačné technológie", tag: "it" },
    { icon: geoIcon, name: "Geografia", tag: "geo" },
    { icon: ecoIcon, name: "Ekonomika", tag: "econ" },
    { icon: anatIcom, name: "Ľudské telo", tag: "human" },
    { icon: techIcon, name: "Technológie", tag: "tech" },
    { icon: scienceIcon, name: "Veda", tag: "science" },
    { icon: historyIcon, name: "História", tag: "hist" },
    { icon: mathIcon, name: "Matematika", tag: "math" },
    { icon: chemIcon, name: "Chémia", tag: "chem" },
    { icon: psychIcon, name: "Psychológia", tag: "psych"},
    {icon: physIcon, name: "Fyzika", tag: "phys"},
    {icon: langIcon, name: "Cudzie jazyky", tag: "lang"},
    

];

const Wrapper = () => {
    return (
       
       
            <div className="wrapper">
                <div className="categories">
                    <h1>Kategórie</h1>
                    <ul>
                        {categories.map((category, index) => (
                            <li key={index}>
                                <Link to={`/category/${category.tag}`}>
                                    <img src={category.icon} alt={category.name} />
                                    <p>{category.name}</p>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
         
       
    );
}

export default Wrapper;
