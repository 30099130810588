import React from "react";
import { useParams } from "react-router-dom";
import "./CategoryPage.css";
import { Link } from "react-router-dom";
import categoryTests from "../categoryTests";
import "./CategoryPageQueries.css";
import { useEffect } from "react";

const CategoryPage = () => {
  const { categoryName } = useParams();
  const tests = categoryTests[categoryName] || [];

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="category-page">
      <div className="wrapper">
        <div className="tests">
          <h1>
            {categoryName
              ? categoryName.replace("-", " ").toUpperCase()
              : "Kategória neexistuje"}
          </h1>
          {tests.length > 0 ? (
            <ul>
              {tests.map((test, index) => (
                <Link to={`/test/${test.name.replace(/\s+/g, "-")}`}>
                  <li
                    key={index}
                    style={{
                      backgroundImage: `url(${test.backgroundImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      textAlign: "center",
                    }}
                  >
                    <p className="test-name">{test.name}</p>
                  </li>
                </Link>
              ))}
            </ul>
          ) : (
            <p>Žiadne testy pre túto kategóriu.</p>
          )}
          <div className="backWrapper">
            <Link to="/">
              <p className="back">Späť na domovskú stránku</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
