import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Zásady ochrany súkromia</h1>
      <p>
        Vaše súkromie je pre nás dôležité. Táto stránka opisuje, aké informácie
        zhromažďujeme, prečo ich zhromažďujeme a ako ich používame.
      </p>

      <h2>1. Aké údaje zhromažďujeme</h2>
      <p>Zbierame rôzne typy údajov, vrátane:</p>
      <ul>
        <li>
          Osobných údajov, ako sú meno a e-mail, keď sa zaregistrujete na našej
          stránke alebo kontaktujete nás.
        </li>
        <li>
          Nefinančné údaje, ako sú IP adresa a informácie o používaní stránky,
          pomocou cookies a podobných technológií.
        </li>
      </ul>

      <h2>2. Ako používame vaše údaje</h2>
      <p>Získané údaje používame na:</p>
      <ul>
        <li>Zlepšenie používateľskej skúsenosti na našej stránke.</li>
        <li>Poskytovanie služieb a podpory na základe vašich požiadaviek.</li>
        <li>
          Analýzu návštevnosti našej stránky pomocou nástrojov tretích strán,
          ako je Google Analytics.
        </li>
      </ul>

      <h2>3. Cookies</h2>
      <p>
        Naša stránka používa cookies na zlepšenie služieb a zabezpečenie lepšej
        používateľskej skúsenosti. Cookies môžete v nastaveniach svojho
        prehliadača kedykoľvek zablokovať alebo odstrániť.
      </p>

      <h2>4. S kým zdieľame vaše údaje</h2>
      <p>
        Vaše údaje nezdieľame s tretími stranami, s výnimkou analytických
        nástrojov, ktoré používame na zlepšenie našich služieb (napríklad Google
        Analytics). Zdieľame len neosobné údaje, ktoré nemožno priamo
        identifikovať.
      </p>

      <h2>5. Vaše práva</h2>
      <p>Máte právo:</p>
      <ul>
        <li>Požiadať o prístup k svojim osobným údajom.</li>
        <li>Požiadať o opravu alebo odstránenie údajov.</li>
        <li>Kedykoľvek odvolať svoj súhlas so spracovaním údajov.</li>
      </ul>

      <h2>6. Kontakt</h2>
      <p>
        
        <a href="mailto:matiassproducer@gmail.com">matiassproducer@gmail.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
