import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import Wrapper from "./Wrapper";
import CategoryPage from "./pages/CategoryPage";
import TestDetailPage from "./pages/TestDetailPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <div className="app">
          <Routes>
            <Route path="/" element={<Wrapper />} />
            <Route path="/category/:categoryName" element={<CategoryPage />} />
            <Route path="/test/:testName" element={<TestDetailPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
